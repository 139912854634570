<template>
  <div>
    <v-row>
      <v-col cols="12" md="3">
        <v-text-field
          v-model="search"
          clearable
          outlined
          dense
          height="40px"
          label="Search name, email, or etc."
          prepend-inner-icon="mdi-magnify"
          color="primary"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <v-autocomplete
          auto-select-first
          clearable
          :menu-props="{
            bottom: true,
            offsetY: true,
          }"
          append-icon="mdi-chevron-down"
          v-model="state"
          :items="state_list"
          @change="getCityList"
          item-text="name"
          item-value="id"
          outlined
          dense
          height="40px"
          label="Filter by State"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="2">
        <v-autocomplete
          auto-select-first
          clearable
          :menu-props="{
            bottom: true,
            offsetY: true,
          }"
          append-icon="mdi-chevron-down"
          v-model="city"
          :items="city_list"
          item-text="name"
          item-value="id"
          outlined
          dense
          height="40px"
          label="Filter by City"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="5" class="text-right">
        <v-btn
          class="text-capitalize px-7"
          color="primary"
          outlined
          height="40px"
          @click="addUniversity({ show: true, id: '', type: 'add' })"
        >
          <v-icon size="16" left>mdi-plus</v-icon>Add New University
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :items-per-page="page_count"
      :server-items-length="page_count"
      :loading="loading"
      loading-text="Please wait.... Loading"
      :headers="headers"
      :items="items"
      hide-default-footer
      mobile-breakpoint
      class="elevation-3"
      color="primary"
      @dblclick:row="onItemDoubleClick"
    >
      <template v-slot:[`item.created`]="{ item }">
        <div class="py-4">
          {{ $utils.getDateFormat(item.created) }}
        </div>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <div class="py-4">
          <div v-if="item.name">{{ item.name }}</div>
          <v-icon v-else>mdi-minus</v-icon>
        </div>
      </template>

      <template v-slot:[`item.contact`]="{ item }">
        <div v-if="item.user.mobile || item.user.email || item.full_address">
          <div v-if="item.user.mobile">{{ item.user.mobile_display }}</div>
          <div v-if="item.user.email">{{ item.user.email }}</div>
          <div v-if="item.full_address">{{ item.full_address }}</div>
        </div>
        <v-icon v-else>mdi-minus</v-icon>
      </template>

      <template v-slot:[`item.bank`]="{ item }">
        <div class="text-caption">
          <div v-if="item.account_number">AC No: {{ item.account_number }}</div>
          <div v-if="item.upi_id">UPI: {{ item.upi_id }}</div>
          <div v-if="item.bank_name">Bank Name: {{ item.bank_name }}</div>
          <div v-if="item.bank_ifsc_code">
            IFSC Code: {{ item.bank_ifsc_code }}
          </div>
          <div v-if="item.bank_branch_name">
            Branch Name: {{ item.bank_branch_name }}
          </div>
        </div>

        <v-edit-dialog
          @save="onSave(item.id, 'bank')"
          @open="onDialogOpen(item, 'bank')"
          large
          transition="slide-y-transition"
        >
          <div class="primary--text d-flex align-center text-caption">
            <v-icon x-small color="primary" class="mr-1">mdi-pencil</v-icon>
            Update
          </div>
          <template v-slot:input>
            <div style="width: 300px">
              <div class="my-4">Bank Details</div>
              <v-text-field
                v-model="editDialog.bank_data.account_number"
                label="Account Number"
                type="text"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="editDialog.bank_data.upi_id"
                label="UPI Address"
                type="text"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="editDialog.bank_data.bank_name"
                label="Bank Name"
                type="text"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="editDialog.bank_data.bank_ifsc_code"
                :rules="[validateIFSC]"
                label="IFSC Code"
                type="text"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="editDialog.bank_data.bank_branch_name"
                label="Branch Name"
                type="text"
                outlined
                dense
              ></v-text-field>
              <v-textarea
                v-model="editDialog.bank_data.bank_branch_address"
                rows="3"
                label="Branch Address"
                type="text"
                outlined
                dense
              ></v-textarea>
            </div>
          </template>
        </v-edit-dialog>
      </template>

      <template v-slot:[`item.remark`]="{ item }">
        <div class="py-4">
          <div v-if="item.remark">{{ item.remark }}</div>
          <v-edit-dialog
            @save="onSave(item.id, 'remark')"
            @open="editDialog.remark = item.remark"
            large
            transition="slide-y-transition"
          >
            <div class="primary--text d-flex align-center text-caption">
              <span v-if="item.remark">
                <v-icon small color="primary">mdi-pencil</v-icon>
                Edit Remark
              </span>
              <span v-else>
                <v-icon small color="primary">mdi-plus</v-icon>
                Add Remark
              </span>
            </div>
            <template v-slot:input>
              <div class="my-4">Remark</div>
              <v-textarea
                v-model="editDialog.remark"
                rows="3"
                type="text"
                flat
                outlined
                dense
              ></v-textarea>
            </template>
          </v-edit-dialog>
        </div>
      </template>

      <template v-slot:[`item.executive`]="{ item }">
        <div class="py-4" v-if="item.executive.length">
          <div v-for="(executive, index) in item.executive" :key="index">
            {{ index + 1 }}. {{ executive.name }}
          </div>
        </div>
        <v-icon v-else>mdi-minus</v-icon>
      </template>

      <template v-slot:[`item.fe_ir`]="{ item }">
        <div class="py-4" v-if="item.fe_ir.length">
          <div v-for="(fe_ir, index) in item.fe_ir" :key="index">
            {{ index + 1 }}. {{ fe_ir.name }}
          </div>
        </div>
        <v-icon v-else>mdi-minus</v-icon>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-switch
          color="success"
          v-model="item.is_active"
          @change="changeStatus(item.is_active, item.id)"
          :label="item.is_active ? 'Active' : 'In-active'"
        ></v-switch>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <div class="d-flex justify-center" style="gap: 10px">
          <v-btn
            small
            outlined
            color="primary"
            class="text-capitalize"
            height="32"
            @click="
              $router.push({
                name: 'viewUniversityManagement',
                params: {
                  id: item.id,
                },
              })
            "
          >
            View
          </v-btn>
          <v-btn
            small
            outlined
            color="primary"
            class="text-capitalize"
            height="32"
            @click="
              addUniversity({
                show: true,
                type: 'edit',
                id: item.id,
              })
            "
          >
            edit
          </v-btn>
          <v-btn
            small
            outlined
            color="primary"
            class="text-capitalize"
            height="32"
            @click="
              toggleConfirmationDialog({ show: true, action: 'delete' }),
                (university_id = item.id)
            "
          >
            delete
          </v-btn>
        </div>
      </template>
    </v-data-table>
    <v-container
      class="d-flex justify-space-between align-center px-0 mb-0 mt-4"
      fluid
    >
      <div class="d-flex align-center text-subtitle-2">
        <div class="mr-2">Rows per page</div>
        <div style="width: 75px">
          <v-select
            :menu-props="{ bottom: true, offsetY: true }"
            :items="itemsPerPageList"
            v-model="page_count"
            hide-details
            dense
            solo
            color="primary"
          ></v-select>
        </div>
        <div class="ml-6">
          Results: {{ pageStart }} - {{ pageEnd }} of {{ total_count }}
        </div>
      </div>
      <v-pagination
        v-model="page_number"
        :length="page_total"
        :total-visible="5"
        color="primary"
      >
      </v-pagination>
    </v-container>
    <ConfirmationDialog @confirmed="deleteUser" height="130px" width="130px">
      <template v-slot:confirmBtnText> Yes Delete </template>
    </ConfirmationDialog>
    <add-university @reload-list="getUniversityList" />
  </div>
</template>
<script>
import _ from "lodash";
import ifsc from "ifsc";
import { mapGetters, mapActions } from "vuex";
import AddUniversity from "./addUniversity.vue";

import ConfirmationDialog from "@/components/Dialogs/ConfirmationDialog";

export default {
  components: { ConfirmationDialog, AddUniversity },
  data() {
    return {
      search: "",
      loading: false,
      itemsPerPageList: [5, 10, 15, 20, 30, 40, 50],
      editDialog: {
        remark: "",
        bank_data: {
          bank_name: "",
          bank_ifsc_code: "",
          bank_branch_name: "",
          bank_branch_address: "",
          account_number: "",
          upi_id: "",
        },
      },
      page_total: 1,
      page_number: 1,
      page_count: 10,
      total_count: 0,
      items: [],
      university_id: "",
      headers: [
        {
          sortable: false,
          text: "Date  Joined",
          value: "created",
          width: 120,
        },
        {
          sortable: false,
          text: "University",
          value: "name",
          width: 250,
        },
        {
          sortable: false,
          text: "Contact Details",
          value: "contact",
        },
        {
          sortable: false,
          text: "Bank Details",
          value: "bank",
          width: 250,
        },
        {
          sortable: false,
          text: "Executive",
          value: "executive",
          width: 150,
        },
        {
          sortable: false,
          text: "FE/IR",
          value: "fe_ir",
          width: 150,
        },
        {
          sortable: false,
          text: "Remark",
          value: "remark",
          width: 150,
        },
        {
          sortable: false,
          text: "Status",
          width: 150,
          value: "status",
        },
        {
          sortable: false,
          text: "Action",
          width: 250,
          value: "action",
        },
      ],
      state: null,
      city_list: [],
      city: null,
    };
  },
  watch: {
    search() {
      this.debouncedReloadData();
    },
    state() {
      this.getUniversityList();
      this.updateRoute();
    },
    city() {
      this.getUniversityList();
      this.updateRoute();
    },
    page_count() {
      this.getUniversityList();
    },
    page_number() {
      this.getUniversityList();
      this.updateRoute();
    },

    "editDialog.bank_data.bank_ifsc_code"(value) {
      if (!ifsc.validate(value)) return;

      ifsc.fetchDetails(value).then((res) => {
        this.editDialog.bank_data.bank_name = res.BANK;
        this.editDialog.bank_data.bank_branch_name = res.BRANCH;
        this.editDialog.bank_data.bank_branch_address = res.ADDRESS;
      });
    },
  },
  computed: {
    ...mapGetters({
      state_list: "getStateList",
    }),

    pageStart() {
      let start = (this.page_number - 1) * this.page_count + 1;
      if (start + 1 > this.total_count) start = this.total_count;
      return start;
    },

    pageEnd() {
      let end = this.pageStart + this.page_count - 1;
      if (end > this.total_count) end = this.total_count;
      return end;
    },
  },

  created() {
    const query = this.$route.query;

    if (query.page) {
      this.page_number = Number(query.page);
    }

    if (query.search) {
      this.search = query.search;
    }

    if (query.state) {
      this.state = Number(query.state);
    }

    if (query.city) {
      this.city = Number(query.city);
      this.getCityList();
    }

    this.updateRoute();
  },

  mounted() {
    this.getUniversityList();
    this.$root.$on("onNavClicked", this.navClickHandler);
  },

  methods: {
    ...mapActions({
      toggleConfirmationDialog: "dialogs/toggleConfirmationDialog",
      setConfirmationDialogBtnLoading:
        "dialogs/setConfirmationDialogBtnLoading",
      addUniversity: "admin/addUniversity",
      showSnackbar: "snackBar/showSnackbar",
    }),

    validateIFSC(value) {
      return value && ifsc.validate(value) ? true : "Invalid IFSC Code";
    },

    navClickHandler(navKey) {
      if (navKey === "university_management") {
        this.getUniversityList();
        this.updateRoute();
      }
    },

    debouncedReloadData: _.debounce(function () {
      this.getUniversityList();
      this.updateRoute();
    }, 1000),

    updateRoute() {
      const query = {};

      if (this.search) {
        query.search = String(this.search);
      }

      if (this.page_number) {
        query.page = Number(this.page_number);
      }

      if (this.city) {
        query.city = Number(this.city);
      }

      if (this.state) {
        query.state = Number(this.state);
      }

      this.$router
        .replace({
          path: this.$route.path,
          query: query,
        })
        .catch((error) => error);
    },

    getCityList() {
      const params = {
        apply_pagination: false,
        state_id: this.state,
      };

      const successHandler = (res) => {
        this.city_list = res.data.data;
      };

      this.$request(this.$keys.GET, this.$urls.master.city.list, {
        params: params,
        onSuccess: successHandler,
      });
    },

    getUniversityList() {
      this.loading = true;
      this.items = [];

      const onSuccess = (res) => {
        this.page_total = res.data.page_info.page_total;
        this.page_number = res.data.page_info.page_number;
        this.total_count = res.data.page_info.total_count;
        this.items = res.data.data;
      };

      const onFinally = () => {
        this.loading = false;
      };

      let params = {};
      params["page_number"] = this.page_number;
      params["page_count"] = this.page_count;
      if (this.search) params["search"] = this.search;
      if (this.state) params["state_id"] = this.state;
      if (this.city) params["city_id"] = this.city;

      return this.$request(this.$keys.GET, this.$urls.university.list, {
        params,
        onSuccess,
        onFinally,
      });
    },

    deleteUser() {
      const onSuccess = (res) => {
        this.toggleConfirmationDialog({ show: false });
        this.showSnackbar({
          text: res.data.message,
          color: "success",
        });
        this.getUniversityList();
      };

      const onFinally = () => {
        this.setConfirmationDialogBtnLoading({ loading: false });
      };

      let params = {};
      params["university_id"] = this.university_id;

      return this.$request(this.$keys.DELETE, this.$urls.university.delete, {
        params,
        onSuccess,
        onFinally,
      });
    },
    changeStatus(value, id) {
      let data = {};
      let params = {};
      params["university_id"] = id;
      data["is_active"] = value;

      return this.$request(this.$keys.PUT, this.$urls.university.update, {
        params,
        data,
      });
    },
    onEdit(params, data) {
      const onSuccess = () => {
        this.getUniversityList();
      };

      return this.$request(this.$keys.PUT, this.$urls.university.update, {
        params,
        data,
        onSuccess,
      });
    },

    onSave(id, field) {
      let formData = {};
      let param = {
        university_id: id,
      };

      switch (field) {
        case "remark": {
          formData["remark"] = this.editDialog.remark;
          this.onEdit(param, formData);
          break;
        }

        case "bank": {
          formData["bank_name"] = this.editDialog.bank_data.bank_name;
          formData["bank_ifsc_code"] = this.editDialog.bank_data.bank_ifsc_code;
          formData["bank_branch_name"] =
            this.editDialog.bank_data.bank_branch_name;
          formData["bank_branch_address"] =
            this.editDialog.bank_data.bank_branch_address;
          formData["account_number"] = this.editDialog.bank_data.account_number;
          formData["upi_id"] = this.editDialog.bank_data.upi_id;

          this.onEdit(param, formData);
          break;
        }
        default:
          break;
      }
    },

    onDialogOpen(item, field) {
      switch (field) {
        case "bank":
          this.editDialog.bank_data = {
            bank_name: item.bank_name,
            bank_ifsc_code: item.bank_ifsc_code,
            bank_branch_name: item.bank_branch_name,
            bank_branch_address: item.bank_branch_address,
            account_number: item.account_number,
            upi_id: item.upi_id,
          };
          break;
        default:
          break;
      }
    },

    closeExecutive() {
      this.executive = null;
    },

    onItemDoubleClick(event, { item }) {
      this.$router.push({
        name: "viewUniversityManagement",
        params: {
          id: item.id,
        },
      });
    },
  },
};
</script>
