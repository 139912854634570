<template>
  <v-dialog
    scrollable
    persistent
    v-model="show"
    max-width="665px"
    content-class="rounded-xl"
  >
    <v-overlay class="justify-center align-center" v-if="loading">
      <v-progress-circular
        :size="100"
        :width="8"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <v-card v-else :outlined="$vuetify.theme.dark" rounded="xl">
      <v-card-title class="d-flex justify-space-between">
        <div>
          <div class="title-name">
            {{ getAddUniversity.type == "edit" ? "Edit" : "Add" }}
            University
          </div>
          <div class="sub-title py-1">Please enter details here</div>
        </div>
        <v-btn icon @click="addUniversity({ show: false, id: '', type: '' })">
          <v-icon size="26">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="raveen">
        <v-form lazy-validation ref="addUniversityForm" class="pt-5">
          <v-row no-gutters>
            <v-col cols="3" class="py-4 mb-3">
              <input
                type="file"
                ref="uploadLogo"
                class="d-none"
                @change="uploadPicture"
                accept="image/*"
              />
              <div
                class="logo-div align-center text-center pointer"
                @click="$refs.uploadLogo.click()"
              >
                <span v-if="!previewFile">
                  <img
                    height="25px"
                    src="@/assets/images/upload-doc.svg"
                    class="mt-6"
                  />
                  <div class="text-caption">Upload Logo</div>
                </span>
                <v-img
                  :lazy-src="blurImg"
                  class="pointer"
                  min-height="108px"
                  style="object-fit: fill; border-radius: 5px"
                  v-else
                  :src="previewFile"
                ></v-img>
              </div>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="form.name"
                label="Name"
                :rules="[rules.required]"
                height="42px"
                type="text"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <div class="d-flex justify-space-between">
                <div style="width: 14%">
                  <v-select
                    :menu-props="{
                      bottom: true,
                      offsetY: true,
                    }"
                    v-model="selectedCountry"
                    :items="country_list"
                    @change="getStateList"
                    item-text="name"
                    return-object
                    height="42px"
                    auto-select-first
                    dense
                    hide-details
                    outlined
                    @input="validate_number"
                  >
                    <template v-slot:selection="data">
                      <span class="mr-2">
                        <v-img
                          :src="data.item.flag"
                          height="20"
                          width="25"
                          class="rounded"
                          v-if="data.item.flag"
                        ></v-img>
                        <div v-else>
                          {{ data.item.iso2_code }}
                        </div>
                      </span>
                    </template>
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title class="d-flex align-center">
                          <v-img
                            :src="data.item.flag"
                            height="20"
                            max-width="25"
                            class="rounded mr-2"
                            v-if="data.item.flag"
                          ></v-img>
                          <div v-else class="mr-2">
                            {{ data.item.iso2_code }}
                          </div>
                          {{ data.item.name }}
                          ({{ data.item.calling_code }})
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-select>
                </div>
                <div style="width: 83%">
                  <v-text-field
                    v-model="form.mob_no"
                    label="Mobile Number"
                    :rules="[isMobileValid]"
                    @input="validate_number"
                    hide-spin-buttons
                    height="42px"
                    type="number"
                    outlined
                    dense
                    @keydown="$rules.blockInvalidChar"
                    :prefix="
                      selectedCountry ? selectedCountry.calling_code : ''
                    "
                  >
                  </v-text-field>
                </div>
              </div>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="form.email"
                label="Email"
                :rules="[$rules.email]"
                height="42px"
                type="email"
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-autocomplete
                auto-select-first
                :menu-props="{
                  bottom: true,
                  offsetY: true,
                }"
                append-icon="mdi-chevron-down"
                v-model="form.state"
                :items="state_list"
                @change="getCityList"
                item-text="name"
                item-value="id"
                flat
                outlined
                label="Select State"
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                auto-select-first
                :menu-props="{
                  bottom: true,
                  offsetY: true,
                }"
                append-icon="mdi-chevron-down"
                v-model="form.city"
                :items="city_list"
                :disabled="form.state == null"
                item-text="name"
                item-value="id"
                flat
                outlined
                dense
                label="Select City"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-textarea
                rows="2"
                type="text"
                v-model="form.address"
                flat
                outlined
                dense
                label="Address"
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-textarea
                rows="2"
                type="text"
                v-model="form.remark"
                flat
                outlined
                dense
                label="Remark"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          @click="submit"
          :loading="btnLoading"
          color="primary"
          class="text-capitalize px-8 my-2"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import RULES from "@/common/fieldRules";
import _ from "lodash";

export default {
  name: "addUniversity",
  data() {
    return {
      loading: false,
      btnLoading: false,
      blurImg: require("@/assets/images/blurImg.png"),
      rules: RULES,
      form: {
        name: "",
        mob_no: "",
        email: "",
        state: null,
        city: null,
        address: "",
        remark: "",
        logo: "",
      },
      previewFile: "",
      selectedCountry: "",
      isMobileValid: true,
      country_list: [],
      state_list: [],
      city_list: [],
    };
  },
  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else this.modalClosed();
    },
  },
  computed: {
    ...mapGetters({
      getAddUniversity: "admin/getAddUniversity",
      getCountryList: "getCountryList",
    }),
    show: {
      get() {
        return this.getAddUniversity.show;
      },
      set(value) {
        this.addUniversity({ show: value });
      },
    },
  },
  methods: {
    ...mapActions({
      addUniversity: "admin/addUniversity",
      showSnackbar: "snackBar/showSnackbar",
    }),
    validate_number() {
      if (this.form.mob_no && this.selectedCountry)
        this.isMobileValid = this.rules.phone_number_validator(
          this.form.mob_no,
          this.selectedCountry.iso2_code
        );
    },

    async openModal() {
      this.loading = true;

      this.getCountryList.forEach((country) => {
        const _country = { ...country };
        _country.calling_code = country.calling_codes
          ? country.calling_codes[0]
          : null;
        this.country_list.push(_country);
      });

      if (this.getAddUniversity.type === "edit") {
        await this.getUniversityDetail();

        if (this.mobile_country_code) {
          this.selectedCountry = _.find(this.country_list, [
            "iso2_code",
            this.mobile_country_code,
          ]);
        } else {
          this.selectedCountry = _.find(this.country_list, ["iso2_code", "IN"]);
        }
      } else {
        this.selectedCountry = _.find(this.country_list, ["iso2_code", "IN"]);
      }

      await this.getStateList();
      await this.getCityList();

      this.loading = false;
    },

    modalClosed() {
      this.$refs.addUniversityForm.reset();
      this.form.name = "";
      this.form.mob_no = "";
      this.form.email = "";
      this.form.address = "";
      this.form.state = null;
      this.form.city = null;
      this.form.logo = "";
      this.form.remark = "";
      this.previewFile = "";
    },

    uploadPicture() {
      let input = this.$refs.uploadLogo;
      let file = input.files;
      this.form.logo = file[0];
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewFile = e.target.result;
        };
        reader.readAsDataURL(file[0]);
      }
    },

    getCityList() {
      const params = {
        apply_pagination: false,
        state_id: this.form.state,
      };

      const successHandler = (res) => {
        this.city_list = res.data.data;
      };

      return this.$request(this.$keys.GET, this.$urls.master.city.list, {
        params: params,
        onSuccess: successHandler,
      });
    },

    getStateList() {
      const params = {
        apply_pagination: false,
        country_id: this.selectedCountry.id,
      };

      const successHandler = (res) => {
        this.state_list = res.data.data;
      };

      return this.$request(this.$keys.GET, this.$urls.master.state.list, {
        params: params,
        onSuccess: successHandler,
      });
    },

    submit() {
      if (this.$refs.addUniversityForm.validate()) {
        let formData = new FormData();

        formData.append("name", this.form.name);
        formData.append("email", this.form.email);

        if (this.form.mob_no) {
          const phone = this.$utils.getPhoneNumberInfo(
            this.form.mob_no,
            this.selectedCountry.iso2_code
          );
          formData.append("mobile", phone.phone_number);
          formData.append("mobile_code", phone.phone_code);
          formData.append("mobile_country_code", phone.country_code);
        } else formData.append("mobile", this.form.mob_no);

        if (this.form.city) formData.append("city", this.form.city);
        if (this.form.address) formData.append("address", this.form.address);
        if (this.form.remark) formData.append("remark", this.form.remark);
        if (this.form.logo) formData.append("logo", this.form.logo);

        this.btnLoading = true;

        const onSuccess = (res) => {
          this.showSnackbar({
            text: res.data.message,
            color: "success",
          });
          this.addUniversity({ show: false, id: "", type: "" });
          this.$emit("reload-list");
        };

        const onFinally = () => {
          this.btnLoading = false;
        };

        if (this.getAddUniversity.id) {
          let params = {};
          params["university_id"] = this.getAddUniversity.id;

          return this.$request(this.$keys.PUT, this.$urls.university.update, {
            params,
            data: formData,
            onSuccess,
            onFinally,
          });
        } else {
          return this.$request(this.$keys.POST, this.$urls.university.create, {
            data: formData,
            onSuccess,
            onFinally,
          });
        }
      } else {
        this.$nextTick(() => {
          const el = document.getElementsByClassName("v-input error--text");
          if (el) el[0].scrollIntoView({ block: "end", behavior: "smooth" });
          return;
        });
      }
    },

    getUniversityDetail() {
      const onSuccess = (res) => {
        this.form.name = res.data.data.name;

        const user = res.data.data.user;

        this.form.mob_no = user.mobile;
        this.form.email = user.email;

        if (user.mobile_country_code)
          this.mobile_country_code = user.mobile_country_code;
        else this.mobile_country_code = "IN";

        this.form.role = res.data.data.role;
        this.form.remark = res.data.data.remark;
        if (res.data.data.city) {
          this.form.city = res.data.data.city.id;
          if (res.data.data.city.state)
            this.form.state = res.data.data.city.state.id;
        }
        this.form.address = res.data.data.address;
        this.form.remark = res.data.data.remark;
        this.previewFile = res.data.data.logo;
      };

      let params = {};
      params["university_id"] = this.getAddUniversity.id;

      return this.$request(this.$keys.GET, this.$urls.university.detail, {
        params,
        onSuccess,
      });
    },
  },
};
</script>
<style scoped>
.title-name {
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: -0.01em;
}
.sub-title {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.01em;
  opacity: 0.6;
}
.logo-div {
  height: 110px;
  width: 110px;
  border-radius: 5px;
  border: 1px dashed #9e9e9e;
}
</style>
